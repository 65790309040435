<template>
  <tabbar route active-color="#0080FF" inactive-color="#B4BFCB">
    <tabbar-item v-for="tab in tabsList" :key="tab.path" replace :to="tab.path" icon-prefix="iconfont" :icon="tab.icon">
      {{ tab.label }}
    </tabbar-item>
  </tabbar>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { tabs } from './config'
import { Tabbar, TabbarItem } from 'vant'
import { fetchMenus } from '@/api'

const URL_MAP = new Map([
  ['文献库', { path: '/home', icon: 'chanpinxinxi', sort: 1 }],
  ['会议速递', { path: '/meeting', icon: 'huiyisudi', sort: 2 }],
  ['智库', { path: '/zhiku', icon: 'zhiku', sort: 3 }],
  ['我的图书馆', { path: '/library', icon: 'yixueyuandi', sort: 4 }],
  ['个人中心', { path: '/profile', icon: 'gerenzhongxin', sort: 5 }]
])

export default defineComponent({
  name: 'Tabs',
  components: {
    Tabbar,
    TabbarItem
  },
  setup() {
    const tabsList = ref([])
    fetchMenus().then(res => {
      const menuArr = res.data
        .filter(item => item.state === 1)
        .map(item => ({
          label: item.name,
          path: URL_MAP.get(item.name).path,
          icon: URL_MAP.get(item.name).icon,
          sort: URL_MAP.get(item.name).sort
        }))
      const arr = tabs.map(item => ({
        ...item,
        sort: URL_MAP.get(item.label).sort
      }))
      tabsList.value = arr.concat(menuArr).sort((a, b) => a.sort - b.sort)
    })
    return {
      tabsList
    }
  }
})
</script>
