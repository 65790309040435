<template>
  <div class="default-layout">
    <router-view v-slot="{ Component }">
      <keep-alive :include="incldeComponents">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <div class="bottom"></div>
  </div>
  <Tabs />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Tabs from '@/components/Tabs'
export default {
  components: { Tabs },
  name: 'layout',
  setup () {
    const store = useStore()
    const incldeComponents = computed(() => {
      return store.state.catchComponents.catch_components
    })

    return {
      incldeComponents
    }
  }
}
</script>

<style lang="less" scoped>
.default-layout {
  width: 100%;
  height: 100%;
  // background: #fff;
  .bottom {
    height: 50px;
  }
}
</style>
